.ratingCardContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .ratingCardContainer {
    flex-direction: column;
  }
}
