.masked-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 10px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .masked-filter {
    flex-wrap: wrap;
  }
}
