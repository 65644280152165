#legend2 {
  position: absolute;
  top: -66px;
  right: 20px;
}
#mobileViewBranchesFilterContainer {
  display: none;
}
#branchesSelect {
  width: 120px;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid lightgray;
}
#branchesSelectFilter2,
#transporterSelectFilter {
  width: 220px;
  padding: 14px;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.refreshBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
}
.excelBar {
  display: none;
}

@media only screen and (max-width: 600px) {
  #legend2 {
    display: none;
  }
  #mobileViewBranchesFilterContainer {
    display: block;
  }
  #branchesSelect {
    width: 100%;
  }
  .paginationGrid {
    display: none;
  }
  .truckSearch {
    display: none;
  }
  .refreshBar {
    flex-direction: column;
  }
  .table-layout {
    display: none;
  }
  .excelBar {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0px;
  }
}
