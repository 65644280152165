.handle {
  //   height: 10px;
  width: 100%;
  display: inline-block;
  border-radius: 0 1px 1px 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.sword {
  height: 23px;

  margin-bottom: 1px;
  text-decoration: line-through;
}
.strike {
  position: relative;
  width: 100%;
}
.strike:before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid green;
  width: 100%;
}
